export default function GetInvolved() {
  return(
    <section id="getInvolved" className="getinvolved--section" >
      <div className="videocontainer">
      <p>Get involved by<h1>Donating Today!</h1></p>
        <video controls autoplay loop muted className="video">
      <source src="./img/donation.mp4" type="video/mp4"/>
        </video>
      </div>
      <div className="abt--section--img">
        
        <img src="./img/mpesa.png" alt="donate" />
      
      </div>
    </section>
  )
}