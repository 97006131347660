import React from 'react';
import { MdEmail, MdPhone, MdAccessTime, MdLocationOn } from 'react-icons/md';

export default function ContactMe() {
  return(
    <section className='contact--section' id='contact'>
    <div className="container">
        <div className="contact-details">
            <h2>Reach Out to Us</h2>
            <p><strong><MdEmail />Email:</strong> saveeachlifefoundation@gmail.com</p>
            <p><strong><MdPhone />Phone:</strong> +254741708225</p>

            <h2>Open Hours</h2>
            <p> <strong><MdAccessTime />Saturday & Sunday: </strong> 3:00PM-6:00PM</p>

            <h2>Location</h2>
            <p><strong><MdLocationOn />English Garden, Mombasa Kenya</strong></p>
        </div>

        <div className="map-container">
            {/* Replace the iframe with your provided iframe code */}
            <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3979.80272048182!2d39.67780361075913!3d-4.060602195896092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x184013c4c0372263%3A0x85af938ae675d2aa!2sENGLISH%20GARDEN%20CHAI%20AND%20KAHAWA!5e0!3m2!1sen!2ske!4v1709274866388!5m2!1sen!2ske"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    </div>
    </section>
  );
}