import AboutMe from "../AboutMe";
import ContactMe from "../ContactMe";
import Footer from "../Footer";
import GetInvolved from "../GetInvolved";
import HeroSection from "../HeroSection";
import Projects from "../Projects";
import Services from "../services";


export default function Home () {
  return(
    <>
    <HeroSection/>
    <Services/>
    <AboutMe/>
    <Projects/>
    <GetInvolved/>
    <ContactMe/>
    <Footer/>
    
    </>
  )
}
