export default function AboutMe(){
  return(
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/about.png" alt="About Us" />
      </div>
      <div className="about--section-description">
        
       <h1 className="about-section--heading">About Us</h1>
       <p className="about-section--p">Saveeachlife foundation is a community based organisation based in Mombasa town, Mwembe-tayari sub county, We majorly deal in marine ecosystems conservation and restoration ,coastal plastic waste management and consulting services, Our Major role is ensuring sustainability for our coastal community prior to SDG 11 on sustainable cities and communities,SDG 13 on climate action,SDG 14 on life below water and SDG 15 on life on land.</p>
      
      </div>
    </section>
  )
}